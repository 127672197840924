html,
body {
  overscroll-behavior-y: none;
  margin: 0;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  font-family: sans-serif;
}

body {
  background-color: #ece9fa;
  background-image: url(./images/mainPageBackground.svg);
  background-size: cover;
}

a {
  text-decoration: none;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
